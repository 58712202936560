import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import LoadingAir from "../LoadingAir/LoadingAir";
import BgForm from "../../images/bg-form.png";
import React from "react";
import { convertFormatDateNoTime } from "../../helpers/formatDate";

export default function Waiting({ airport, time }) {
  return (
    <div className="waiting-page h-[calc(100vh-120px)] bg-white px-0 py-4 items-center flex flex-col">
      <img className="absolute !top-[100px]" src={BgForm} alt="" />
      <div>
        <LoadingAir />
        <div className="flex w-full top-[135px] left-1/2 justify-center items-center gap-8 mt-2">
          <div className="flex flex-col gap-1">
            <div className="text-white font-semibold text-[18px]">
              {airport?.[0]}
            </div>
          </div>
          <div className="relative">
            <img src={"/images/ic-flights.svg"} alt="" />
          </div>
          <div className="flex flex-col gap-1">
            <div className="text-white font-semibold text-[18px]">
              {airport?.[1]}
            </div>
          </div>
        </div>
        <div className="text-[14px] font-medium text-center mb-[14px] text-white justify-center">
          {`${convertFormatDateNoTime(time[0])} ${time[1] ? `- ${convertFormatDateNoTime(time[1])}` : ""}`}
        </div>
      </div>

      <div>
        <p className="text-[14px] font-semibold text-center my-[26px] text-white">
          Vui lòng đợi trong giây lát <br />
          hệ thống đang tìm kiếm chuyến bay ...
        </p>

        {/* <div className="text-[14px] font-medium text-center mb-3 flex gap-4 justify-center">
          <div className="text-white">{airport[0]}</div>
          <img src="/images/2-direction.svg" alt="" className="mx-4" />
          <div className="text-white">{airport[1]}</div>
        </div> */}
        <div className="flex justify-center">
          <Spin
            size="large"
            className="text-center"
            indicator={<LoadingOutlined spin />}
          />
        </div>
      </div>
    </div>
  );
}

import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Switch,
  message,
} from "antd";
import CalendarCustom from "../Calendar/CalendarCustom";
import "react-calendar/dist/Calendar.css";
import locale from "antd/es/date-picker/locale/vi_VN";
import {
  calculateTimeDifference,
  formatNumberWithDots,
} from "../../helpers/utils"; // Import locale cho DatePicker
import dayjs from "dayjs";
import "moment/locale/vi"; // Import locale tiếng Việt
import React, { useEffect, useMemo, useState } from "react";
import BgForm from "../../images/bg-form.png";
import $axios from "../../services/axios";
import CustomInputNumber from "../Common/CustomInputNumber";
import TicketDetailsDrawer from "../Common/DetailTicket";
import { dateFilterAir } from "../../helpers/formatDate";

const Step1 = ({
  form,
  onChangeStep,
  tripType,
  setTripType,
  setAirport,
  airports,
  loadingAirPorts,
  setFilterDay,
  setFilterDayReturn,
}) => {
  const listAirPortVn = ["HAN", "SGN", "DAD", "CXR", "HPH", "PQC"];
  const listAirPortGlobal = [
    "DA",
    "DB",
    "AS",
    "TQ",
    "EU",
    "SA",
    "NA",
    "AF",
    "OC",
  ];
  const StartPoint = Form.useWatch("StartPoint", form);
  const EndPoint = Form.useWatch("EndPoint", form);

  const [valueCalendar, setValueCalendar] = useState(new Date());
  const [airportsDom, setAirportsDom] = useState([]);
  const [airportsFor, setAirportsFor] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [nameField, setNameField] = useState("StartPoint");
  const [cheapFlights, setcheapFlights] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [detailTicket, setDetailTicket] = useState({});
  const [openCalendar, setOpenCalendar] = useState(false);
  const [typeCalendar, setTypeCalendar] = useState("");
  const [minDateCalendar, setMinDateCalendar] = useState(new Date());

  // Hàm xử lý chọn sân bay
  const handleSelect = (value) => {
    form.setFieldValue(nameField, value);
    setOpenDrawer(false); // Đóng drawer sau khi chọn
  };

  const removeDiacritics = (str) => {
    if (!str) return ""; // Xử lý trường hợp chuỗi null hoặc undefined
    return str
      .normalize("NFD") // Phân tách ký tự có dấu
      .replace(/[\u0300-\u036f]/g, "") // Loại bỏ dấu
      .replace(/đ/g, "d") // Thay thế "đ" thành "d"
      .replace(/Đ/g, "D"); // Thay thế "Đ" thành "D"
  };

  // Lọc sân bay theo từ khóa tìm kiếm
  const filterAirports = useMemo(() => {
    if (airportsDom.length) {
      const arr = [];

      const airPortFilter = airportsDom.filter((item) =>
        removeDiacritics(
          `${item.name_vi} ${item?.city_name_vi} ${item.city_code} ${item.country_name_en}`
        )
          .toLowerCase()
          .includes(removeDiacritics(searchTerm).toLowerCase())
      );

      listAirPortVn.forEach((code) => {
        const index = airPortFilter.findIndex((item) => item.code === code);
        if (index > -1) {
          arr.push(airPortFilter[index]);
          airPortFilter.splice(index, 1);
        }
      });

      arr.push(...airPortFilter);

      return arr;
    }
    return [];
  }, [airportsDom, searchTerm]);

  const filterAirportsFor = useMemo(() => {
    if (airportsFor.length) {
      const globalFilter = airportsFor.filter((item) =>
        removeDiacritics(
          `${item.name_vi} ${item?.city_name_vi} ${item.city_code} ${item.country_name_en}`
        )
          .toLowerCase()
          .includes(removeDiacritics(searchTerm).toLowerCase())
      );

      const classifiedByCountry = globalFilter.reduce((acc, item) => {
        const { continent_code } = item;
        if (!acc[continent_code]) {
          acc[continent_code] = [];
        }
        if (acc[continent_code].length < 30) {
          acc[continent_code].push(item);
        }
        return acc;
      }, {});

      return listAirPortGlobal.map((item) => classifiedByCountry[item]);
    }

    return [];
  }, [airportsFor, searchTerm]);

  const onFinish = async (values) => {
    const startDate = new Date(values.DepartDate);
    const returnDate = new Date(values.ReturnDate);
    setAirport([values.StartPoint, values.EndPoint]);
    setFilterDay(dateFilterAir(startDate));
    values.ReturnDate && setFilterDayReturn(dateFilterAir(returnDate));
    onChangeStep(2);
  };

  useEffect(() => {
    setAirportsDom(
      airports.filter(
        (item) => item.country_code === "VN" && item.code !== "VCL"
      )
    );

    setAirportsFor(airports.filter((item) => item.country_code !== "VN"));
  }, [airports]);

  const handleSwap = () => {
    form.setFieldsValue({
      StartPoint: form.getFieldValue("EndPoint"),
      EndPoint: form.getFieldValue("StartPoint"),
    });
  };
  const [departureDate, setDepartureDate] = useState(dayjs());
  const handleValuesFormChange = (changedValues, allValues) => {
    const DepartDate = allValues.DepartDate;
    const ReturnDate = allValues.ReturnDate;
    if (DepartDate && DepartDate.isBefore(dayjs(), "day")) {
      message.error("Ngày đi phải lớn hơn hoặc bằng ngày hiện tại!");
      form.setFieldsValue({ DepartDate: null });
      setDepartureDate(null);
    }
    if (ReturnDate && DepartDate && ReturnDate.isBefore(DepartDate, "day")) {
      message.error("Ngày về phải lớn hơn hoặc bằng ngày đi!");
      form.setFieldsValue({ ReturnDate: null });
    }
    if (allValues.DepartDate) {
      setDepartureDate(allValues.DepartDate);
    }
  };

  const getDiscount = async () => {
    const values = form.getFieldsValue();
    try {
      const response = await $axios.$post(`flights/searchmonth`, {
        StartPoint: values.StartPoint,
        EndPoint: values.EndPoint,
        Airline: "",
        Month: dayjs().get("month") + 1,
        Year: dayjs().get("year"),
      });

      setcheapFlights(response.data.ListMinPrice || []);
    } catch (error) {
      return null;
    }
  };
  useEffect(() => {
    if (StartPoint !== EndPoint) {
      getDiscount();
    }
  }, [StartPoint, EndPoint]);

  const handleSelectCheapFlight = (flight) => {
    setTripType("oneWay");
    setAirport([
      form.getFieldValue("StartPoint"),
      form.getFieldValue("EndPoint"),
    ]);
    setFilterDay(dateFilterAir(flight.ListFlight[0].StartDate));
    onChangeStep(2);
  };

  const onOpenChange = (type) => {
    setValueCalendar(form.getFieldValue(type) ?? new Date());
    setOpenCalendar(true);
    setTypeCalendar(type);
    if (type === "ReturnDate") {
      setMinDateCalendar(new Date(form.getFieldValue("DepartDate")));
    } else {
      setMinDateCalendar(new Date());
    }
  };

  const setValue = (value) => {
    form.setFieldValue(typeCalendar, dayjs(value));
    setValueCalendar(value);
    if (
      typeCalendar === "DepartDate" &&
      dayjs(value) > form.getFieldValue("ReturnDate")
    ) {
      const newReturnDate = dayjs(value).add(3, "day");
      form.setFieldValue("ReturnDate", newReturnDate);
    }
    setOpenCalendar(false);
  };

  return (
    <div className="min-h-screen flex flex-col items-center step1  overflow-scroll">
      <CalendarCustom
        openCalendar={openCalendar}
        setOpenCalendar={setOpenCalendar}
        typeCalendar={typeCalendar}
        form={form}
        valueCalendar={valueCalendar}
        setValue={setValue}
        minDate={minDateCalendar}
      />
      <>
        <div className="w-full 0 bg-[#3691e2] shadow-lg">
          <img src={BgForm} alt="" className="w-full object-cover mx-auto" />
          <div className="w-[90%] mx-auto absolute top-[30px] left-1/2 transform -translate-x-1/2 flex flex-col">
            <div className="flex justify-between items-center">
              <div className="w-[32px]"></div>
              <div className="">
                <img src={"/images/flight-icon.svg"} alt="" />
              </div>
              <div
                className="flex gap-2 items-center cursor-pointer"
                onClick={() => onChangeStep(0)}
              >
                <img
                  className="w-[32px] p-1 h-[32px] bg-white rounded-full"
                  src={"/images/history.svg"}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="w-[90%] mx-auto absolute top-[15%] left-1/2 transform -translate-x-1/2 -translate-y-4/5 pb-[40px] flex flex-col ">
            <Form
              form={form}
              onFinish={onFinish}
              layout="vertical"
              onValuesChange={handleValuesFormChange}
              onFinishFailed={(values) => {}}
              initialValues={{
                StartPoint: "HAN",
                EndPoint: "SGN",
                DepartDate: dayjs(),
              }}
              className="form-search"
            >
              <div
                style={{
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                }}
                className=" rounded-[20px] bg-white p-4"
              >
                <Row
                  gutter={16}
                  className="items-center"
                  justify={"space-between"}
                >
                  <Col
                    span={11}
                    style={{
                      paddingRight: 0,
                    }}
                  >
                    {/* Điểm đi */}
                    <Form.Item label="Điểm đi" name={"StartPoint"}>
                      <Select
                        className="w-full"
                        suffixIcon={
                          <img
                            className="prefix-icon"
                            alt=""
                            src={"/images/take-on.svg"}
                          />
                        }
                        showSearch={false} // Ẩn menu Select mặc định
                        open={false} // Ẩn menu mặc định của Select
                        onClick={() => {
                          setOpenDrawer(true);
                          setNameField("StartPoint");
                        }} // Mở drawer khi nhấn vào Select
                        options={[
                          {
                            label: <span>Nội địa</span>,
                            title: "Nội địa",
                            options: airportsDom.map((item) => ({
                              label: (
                                <span className="flex flex-col">
                                  <span className="leading-4 text-[12px] font-medium">
                                    {" "}
                                    {item?.city_name_vi}
                                  </span>
                                  <span className="leading-4 text-[12px] font-medium">
                                    {item.city_code}
                                  </span>
                                </span>
                              ),
                              title: `${item.name_vi} ${item?.city_name_vi} ${item.city_code}`, // Thêm thuộc tính title cho filter
                              value: item.code,
                            })),
                          },
                          {
                            label: <span>Quốc tế</span>,
                            title: "Quốc tế",
                            options: airportsFor.map((item) => ({
                              label: (
                                <span className="flex flex-col">
                                  <span className="leading-4 text-[12px] font-medium">
                                    {" "}
                                    {item?.city_name_vi}
                                  </span>
                                  <span className="leading-4 text-[12px] font-medium">
                                    {item.city_code}
                                  </span>
                                </span>
                              ),
                              title: `${item.name_vi} ${item?.city_name_vi} ${item.city_code}`, // Thêm thuộc tính title cho filter
                              value: item.code,
                            })),
                          },
                        ]}
                      />
                    </Form.Item>
                    {!loadingAirPorts && (
                      <div className="location">
                        <Drawer
                          title={
                            <h1 className="text-lg font-bold">
                              Chọn điểm{" "}
                              {nameField === "StartPoint" ? "đi" : "đến"}
                            </h1>
                          }
                          placement="bottom"
                          open={openDrawer}
                          onClose={() => setOpenDrawer(false)}
                          height="100vh"
                        >
                          <div className="p-4">
                            <Input
                              placeholder="Tìm kiếm sân bay"
                              value={searchTerm}
                              onChange={(e) => setSearchTerm(e.target.value)}
                              className="mb-4"
                            />

                            <div className="airport-list overflow-auto">
                              {!!filterAirports.length && (
                                <>
                                  <h4 className="mb-2 text-md font-semibold text-primary">
                                    Việt Nam
                                  </h4>
                                  <div className="flex gap-2.5 flex-wrap">
                                    {filterAirports.map((item) => (
                                      <div
                                        className="h-8 flex items-center rounded-lg px-2 bg-[#EDF2F7]"
                                        onClick={() => handleSelect(item.code)}
                                      >
                                        {item?.name_vi.replace("Sân bay ", "")}
                                      </div>
                                    ))}
                                  </div>
                                </>
                              )}

                              {filterAirportsFor.map((item) => (
                                <div>
                                  <h4 className="my-2 text-md font-semibold text-primary">
                                    {item?.[0]?.continent_name_vi}
                                  </h4>
                                  <div className="flex gap-2 flex-wrap">
                                    {item?.map((airport) => (
                                      <div
                                        className="h-8 flex items-center rounded-lg px-2 bg-[#EDF2F7]"
                                        onClick={() =>
                                          handleSelect(airport.code)
                                        }
                                      >
                                        {airport?.name_vi.replace(
                                          "Sân bay ",
                                          ""
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </Drawer>
                      </div>
                    )}
                  </Col>
                  <Col span={2} className="flex items-center justify-center">
                    <img
                      src={"/images/swap.svg"}
                      alt=""
                      className="cursor-pointer"
                      onClick={() => handleSwap()}
                    />
                  </Col>
                  <Col
                    span={11}
                    style={{
                      paddingLeft: 0,
                    }}
                    className="pl-0"
                  >
                    {/* Điểm đến */}
                    <Form.Item
                      className="form-item-right-label"
                      label="Điểm đến"
                      name={"EndPoint"}
                    >
                      <Select
                        className="w-full h-16"
                        suffixIcon={
                          <img
                            className="prefix-icon"
                            src={"/images/take-off.svg"}
                            alt=""
                          />
                        }
                        showSearch={false} // Ẩn menu Select mặc định
                        open={false} // Ẩn menu mặc định của Select
                        onClick={() => {
                          setOpenDrawer(true);
                          setNameField("EndPoint");
                        }} // Mở drawer khi nhấn vào Select
                        options={[
                          {
                            label: <span>Nội địa</span>,
                            title: "Nội địa",
                            options: airportsDom.map((item) => ({
                              label: (
                                <span className="flex flex-col">
                                  <span className="leading-4 text-[12px] font-medium">
                                    {" "}
                                    {item?.city_name_vi}
                                  </span>
                                  <span className="leading-4 text-[12px] font-medium">
                                    {item.city_code}
                                  </span>
                                </span>
                              ),
                              title: `${item.name_vi} ${item?.city_name_vi} ${item.city_code}`, // Thêm thuộc tính title cho filter
                              value: item.code,
                            })),
                          },
                          {
                            label: <span>Quốc tế</span>,
                            title: "Quốc tế",
                            options: airportsFor.map((item) => ({
                              label: (
                                <span className="flex flex-col">
                                  <span className="leading-4 text-[12px] font-medium">
                                    {" "}
                                    {item?.city_name_vi}
                                  </span>
                                  <span className="leading-4 text-[12px] font-medium">
                                    {item.city_code}
                                  </span>
                                </span>
                              ),
                              title: `${item.name_vi} ${item?.city_name_vi} ${item.city_code}`, // Thêm thuộc tính title cho filter
                              value: item.code,
                            })),
                          },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                {/* Ngày đi */}
                <div className="flex items-center justify-between w-full mb-2">
                  <label className="font-medium text-[#22313F]">Ngày đi</label>
                  <div className="flex items-center gap-2">
                    <Switch
                      checked={tripType === "roundTrip"}
                      onChange={(checked) => {
                        setTripType(checked ? "roundTrip" : "oneWay");
                      }}
                    />{" "}
                    <label className="font-medium text-[#22313F]">
                      Khứ hồi
                    </label>
                  </div>
                </div>
                {tripType === "oneWay" ? (
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn ngày!",
                      },
                    ]}
                    name={"DepartDate"}
                  >
                    <DatePicker
                      open={false}
                      className="w-full"
                      placeholder="Chọn ngày"
                      format="DD/MM/YYYY"
                      minDate={dayjs()}
                      locale={locale} // Thiết lập locale tiếng Việt
                      suffixIcon={<img src={"/images/date.svg"} alt="" />}
                      allowClear={false}
                      inputReadOnly
                      onOpenChange={() => onOpenChange("DepartDate")}
                    />
                  </Form.Item>
                ) : (
                  <Row gutter={12}>
                    <Col span={12}>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Vui lòng chọn ngày!",
                          },
                        ]}
                        name={"DepartDate"}
                      >
                        <DatePicker
                          open={false}
                          minDate={dayjs()}
                          style={{ width: "100%" }}
                          placeholder="Chọn ngày"
                          locale={locale} // Thiết lập locale tiếng Việt
                          format="DD/MM/YYYY"
                          suffixIcon={<img src={"/images/date.svg"} alt="" />}
                          allowClear={false}
                          inputReadOnly
                          onOpenChange={() => onOpenChange("DepartDate")}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Vui lòng chọn ngày!",
                          },
                        ]}
                        name={"ReturnDate"}
                      >
                        <DatePicker
                          open={false}
                          minDate={dayjs(departureDate)}
                          style={{ width: "100%" }}
                          placeholder="Chọn ngày"
                          locale={locale} // Thiết lập locale tiếng Việt
                          format="DD/MM/YYYY"
                          suffixIcon={<img src={"/images/date.svg"} alt="" />}
                          allowClear={false}
                          inputReadOnly
                          onOpenChange={() => onOpenChange("ReturnDate")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
                {/* Hành khách */}
                <div className="flex justify-between items-center">
                  <div className="flex flex-col grow">
                    <div>
                      <CustomInputNumber
                        form={form}
                        name="Adt"
                        label={
                          <span className="mb-1 text-[14px] font-medium text-[#22313F]">
                            Người lớn <img src="/images/aldult.svg" alt="" />
                          </span>
                        }
                        min={0}
                        max={9}
                        defaultValue={1}
                      />
                    </div>
                    <p className="mt-2 text-[14px] text-[#808080] text-center font-normal">
                      (12+ tuổi)
                    </p>
                  </div>
                  <div className="flex flex-col grow">
                    <div>
                      <CustomInputNumber
                        form={form}
                        name="Chd"
                        label={
                          <span className="mb-1 text-[14px] font-medium text-[#22313F]">
                            Trẻ em <img src="/images/child.svg" alt="" />
                          </span>
                        }
                        min={0}
                        max={9}
                        defaultValue={0}
                      />
                      <p className="mt-2 text-[14px] text-[#808080]  text-center font-normal">
                        (2-12 tuổi)
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col grow">
                    <div>
                      <CustomInputNumber
                        form={form}
                        name="Inf"
                        label={
                          <span className="mb-1 text-[14px] font-medium text-[#22313F]">
                            Em bé <img src="/images/baby.svg" alt="" />
                          </span>
                        }
                        min={0}
                        max={2}
                        defaultValue={0}
                      />

                      <p className="mt-2 text-[14px] text-[#808080] text-center font-normal">
                        (Dưới 2 tuổi)
                      </p>
                    </div>
                  </div>
                </div>
                <span className="text-[14px] text-center block mt-4">
                  <span className="text-[#808080] font-normal">
                    {" "}
                    Bạn đã có mã đơn hàng?{" "}
                  </span>
                  <span
                    className="text-[#0064D2] font-bold cursor-pointer"
                    onClick={() => onChangeStep(0)}
                  >
                    Tra cứu ngay
                  </span>
                </span>
              </div>
              {/* Button tìm chuyến bay */}
              <Form.Item>
                <div className="text-center">
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="w-[90%] bg-[#0064D2] hover:bg-[#0064D2] rounded-lg mt-4 h-[48px] text-[16px] font-semibold mx-auto"
                  >
                    Tìm chuyến bay
                  </Button>
                </div>
              </Form.Item>
            </Form>
            {/* Hotline */}
            <div className="text-center">
              <span className="text-[#808080] font-normal">
                Hotline hỗ trợ khách hàng{" "}
              </span>
              <a
                href="tel:0876100800"
                className="font-bold text-[#0064D2] text-[16px]"
              >
                0876100800
              </a>
            </div>
            {/* Danh sách chuyến bay */}
            {(cheapFlights || []).length > 0 && (
              <div className="mb-8">
                <h1 className="font-bold text-[16px] text-primary my-6">
                  Vé siêu khuyến mại
                </h1>
                {(cheapFlights || []).slice(1, 8).map((airline, index) =>
                  (airline.ListFareData || [])
                    .slice(0, 1)
                    .map((item, flightIndex) => (
                      <div
                        key={flightIndex}
                        className="w-full px-3 py-4 bg-white rounded-[20px] mb-4"
                        style={{
                          boxShadow: "0px 4px 4px 4px rgba(0, 0, 0, 0.25)",
                        }}
                      >
                        <Row
                          gutter={8}
                          className="justify-between items-center"
                        >
                          <Col>
                            <Row gutter={4} align={"middle"}>
                              <Col>
                                <div className="flex flex-col items-center gap-1">
                                  <h4 className="font-bold text-[11px]">
                                    {item?.ListFlight?.[0]?.StartDt.split(
                                      " "
                                    )[1].split(":")[0] +
                                      ":" +
                                      item?.ListFlight?.[0]?.StartDt.split(
                                        " "
                                      )[1].split(":")[1]}
                                  </h4>
                                  <p className="text-[12px]">
                                    {item?.ListFlight?.[0].StartPoint}
                                  </p>
                                  <p className="text-[10px] text-[#808089]">
                                    {dayjs(
                                      item?.ListFlight?.[0].StartDate
                                    ).format("DD/MM/YYYY")}
                                  </p>
                                </div>
                              </Col>
                              <Col className="justify-center text-center">
                                <p className="text-[10px] text-[#808089]">
                                  {calculateTimeDifference(
                                    item?.ListFlight?.[0]?.StartDate,
                                    item?.ListFlight?.[
                                      item.ListFlight.length - 1
                                    ]?.EndDate
                                  )}
                                </p>
                                <img src="/images/flight-line.svg" alt="" />

                                <p className="text-[10px] text-[#808089]">
                                  {(item.ListFlight || []).length === 1
                                    ? "Bay thẳng"
                                    : `${
                                        (item.ListFlight || []).length - 1
                                      } điểm dừng`}
                                </p>
                              </Col>
                              <Col>
                                <div className="flex flex-col items-center gap-1">
                                  <h4 className="font-bold text-[12px]">
                                    {item?.ListFlight?.[
                                      item.ListFlight.length - 1
                                    ]?.EndDt.split(" ")[1].split(":")[0] +
                                      ":" +
                                      item?.ListFlight?.[
                                        item.ListFlight.length - 1
                                      ]?.EndDt.split(" ")[1].split(":")[1]}
                                  </h4>
                                  <p className="text-[11px] text-center">
                                    {
                                      item?.ListFlight?.[
                                        item.ListFlight.length - 1
                                      ].EndPoint
                                    }
                                  </p>
                                  <p className="text-[8px] text-[#808089]">
                                    {dayjs(
                                      item?.ListFlight?.[0].EndDate
                                    ).format("DD/MM/YYYY")}
                                  </p>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col>
                            <div className="flex gap-2 items-center mb-2">
                              <div className="font-bold text-primary text-[11px] ">
                                {formatNumberWithDots(item.TotalPrice)}{" "}
                                {item.Currency}
                              </div>
                              <Button
                                className="rounded-[16px] p-1 px-2 h-6 text-[10px]"
                                type="primary"
                                onClick={() => handleSelectCheapFlight(item)}
                              >
                                <p className="text-white text-[10px]">
                                  Chọn vé
                                </p>
                              </Button>
                            </div>

                            <div className="flex gap-2 items-center justify-between">
                              <p className="text-[10px] text-[#808089]">
                                /1 Hành khách
                              </p>
                              <p
                                className="text-primary text-[10px] flex gap items-center cursor-pointer"
                                onClick={() => {
                                  setDetailTicket(item);
                                  setDrawerVisible(true);
                                }}
                              >
                                Chi tiết <img src="/images/2-down.svg" alt="" />
                              </p>
                            </div>
                          </Col>
                        </Row>
                        <hr className="my-2" />
                        <Row className="justify-between items-center">
                          <div>
                            <div className="flex gap-2 items-center">
                              <img
                                className="w-auto h-4"
                                src={`https://abtrip.vn/images/airlines/${item.Airline.toLowerCase()}.gif`}
                                alt=""
                              />
                              <div className="flex flex-col gap-1">
                                <p className="text-[10px]">
                                  {item.Airline || ""}
                                </p>
                                <p className="text-[10px] text-primary">
                                  {item?.ListFlight?.[0]?.FlightNumber || ""}/{" "}
                                  {item?.ListFlight?.[0]?.GroupClass}
                                </p>
                              </div>
                            </div>
                          </div>
                        </Row>
                        {dayjs(item?.ListFlight?.[0].StartDate).isSame(
                          dayjs(),
                          "day"
                        ) && (
                          <p className="text-red-500 text-[10px] mt-1">
                            Vé không thể giữ chỗ, cần thanh toán ngay
                          </p>
                        )}
                      </div>
                    ))
                )}
              </div>
            )}
          </div>
        </div>

        <TicketDetailsDrawer
          visible={drawerVisible}
          onClose={() => setDrawerVisible(false)}
          ticketDetails={detailTicket}
          airports={airports}
          handleSelectTicket={handleSelectCheapFlight}
        />
      </>
    </div>
  );
};

export default Step1;
